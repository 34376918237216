import React from "react";
import {useEffect, useState} from "react";
import CustomSelect from "components/Shared/CustomSelect";

// react-bootstrap components
import {
    Form,
    Card,
    Col,
    Button,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
    Dropdown,
    Spinner,
} from "react-bootstrap";

import {formatCurrency} from "utils/FormatCurrency.js";

function SalesAndInventory(props) {
    const renderInventoryTable = () => {
        if (props.inventoryData.length > 0) {
            {
                return (
                    <Table className="table-hover table-striped w-full">
                        <thead style={{zIndex: "1000"}}>
                        <tr>
                            <th>Location</th>
                            {props.inventoryData.length > 0 &&
                                props.inventoryData[0].sizesInStockAndSold.map((size) => (
                                    <th key={size}>{size.sizeName}</th>
                                ))}
                        </tr>
                        </thead>
                        <tbody>
                        {props.inventoryData.map((item, i) => {
                            return (
                                <tr key={i}>
                                    <td>{item.locationName}</td>
                                    {props.inventoryData.length > 0 &&
                                        props.inventoryData[i].sizesInStockAndSold.map(
                                            (size, i) => {
                                                const handleGetWebOrdersInformation = (
                                                    type,
                                                    productId,
                                                    locationId
                                                ) => {
                                                    props.handleShowWebOrdersModal();
                                                    props.handleGetItemCardWebOrderInfo(
                                                        type,
                                                        productId,
                                                        locationId
                                                    );
                                                };
                                                return (
                                                    <td key={i} style={{minWidth: "100px"}}>
                                                        <span>{size.inStock}</span> /{" "}
                                                        <span
                                                            style={{cursor: "pointer"}}
                                                            onClick={() =>
                                                                size.committed > 0 &&
                                                                handleGetWebOrdersInformation(
                                                                    "Committed",
                                                                    size.productId,
                                                                    item.locationId
                                                                )
                                                            }
                                                        >
                                {size.committed}
                              </span>{" "}
                                                        /{" "}
                                                        <span
                                                            style={{cursor: "pointer"}}
                                                            onClick={() =>
                                                                size.inTransit > 0 &&
                                                                handleGetWebOrdersInformation(
                                                                    "InTransit",
                                                                    size.productId,
                                                                    item.locationId
                                                                )
                                                            }
                                                        >
                                {size.inTransit}
                              </span>
                                                        <span
                                                            style={{cursor: "pointer"}}
                                                            onClick={() => {
                                                                size.sold > 0 &&
                                                                handleGetWebOrdersInformation(
                                                                    "Sold",
                                                                    size.productId,
                                                                    item.locationId
                                                                );
                                                            }}
                                                        >
                                {" "}
                                                            ({size.sold}){" "}
                              </span>
                                                    </td>
                                                );
                                            }
                                        )}
                                    <td className="td-actions text-right">
                                        {props.canEdit && (
                                            <Button
                                                className="btn-link btn-xs btn-action"
                                                variant="danger"
                                                onClick={() => props.handleShowInventoryModal(item)}
                                            >
                                                <i className="fas fa-edit"></i>
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                );
            }
        }
    };

    return (
        <Card.Body className="table-responsive p-0 item-card-tab">
            <Col md="12">
                <Form action="" className="form" method="">
                    <Row>
                        <Col md="3">
                            <Form.Group>
                                <label>Season</label>
                                <CustomSelect
                                    placeholder="Season"
                                    value={props.selectedSeasonId}
                                    data={props.seasons}
                                    handleChange={(e) => props.handleSeasonChange(e)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="" md="3">
                            <Form.Group>
                                <label>Cost</label>
                                <Form.Control
                                    className=""
                                    placeholder="Cost"
                                    type="text"
                                    value={
                                        props.hasFullViewPermission
                                            ? formatCurrency(props.salesData.cost)
                                            : formatCurrency(0)
                                    }
                                    disabled
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col className="pr-1" md="3">
                            <Form.Group>
                                <label>Sales</label>
                                <Form.Control
                                    placeholder="Sales (After Tax)"
                                    type="text"
                                    value={formatCurrency(props.salesData.sales)}
                                    disabled
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col className="pr-1" md="3">
                            <Form.Group>
                                <label>Profit</label>
                                <Form.Control
                                    placeholder="Profit"
                                    type="text"
                                    value={formatCurrency(props.salesData.profit)}
                                    disabled
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col className="pr-1" md="3">
                            <Form.Group>
                                <label>Qty</label>
                                <Form.Control
                                    placeholder="Qty"
                                    type="number"
                                    value={props.salesData.quantity}
                                    disabled
                                ></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row style={{
                        maxHeight: "600px",
                        overflowY: "scroll",
                        overflowX: "auto",
                    }}>
                        <Col>
                            <Row>
                                <Col
                                    className="mr-auto d-flex justify-content-end"
                                    style={{
                                        fontSize: "10px",
                                        margin: "35px 0 0 0",
                                        paddingRight: "20px",
                                    }}
                                >
                                    Key: Stock / Committed / In Transit (Sold)
                                </Col>
                            </Row>
                            <Card.Body className="table-responsive p-0 inventory-table">
                                <Col md="12">
                                    {props.isLoading ? (
                                        <div
                                            style={{height: 550}}
                                            className="d-flex justify-content-center align-items-center text-center"
                                        >
                                            <div>
                                                <Spinner animation="border"/>
                                                <p>Loading...</p>
                                            </div>
                                        </div>
                                    ) : (
                                        renderInventoryTable()
                                    )}
                                </Col>
                            </Card.Body>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Card.Body>
    );
}

export default SalesAndInventory;
