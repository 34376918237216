import React, { useEffect, useState } from "react";
import CustomTable from "components/Shared/CustomTable";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip,
  Container,
  Spinner,
  Form,
  CardHeader,
} from "react-bootstrap";
import StocktakeLinesTable from "./StocktakeLinesTable";

function StocktakeLineModal(props) {
  useEffect(() => {
    // set the width of the modal
    document.documentElement.style.setProperty("--modal-width", "1400px");
  }, []);

  var hasPermission = false;
  var permissions = localStorage.getItem("permissions");

  return (
    <>
      {alert}
      <Container fluid className="PurchaseModal">
        <Col md="12">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Stocktake Order Lines</Card.Title>
            </Card.Header>
          </Card>
        </Col>
      </Container>
      <Container fluid className="PurchaseModalTable">
        <Row>
          <Col md="12">
            <StocktakeLinesTable
              data={props.data}
              stocktakeStatus={props.stocktakeStatus}
              stocktakeLocationId={props.stocktakeLocationId}
              selectAll={props.selectAll}
              checkedItems={props.checkedItems}
              setCheckedItems={props.setCheckedItems}
              handleShowApproveAdjustmentsModal={
                props.handleShowApproveAdjustmentsModal
              }
              handleConfirmStocktake={props.handleConfirmStocktake}
              handleAdjustTotal={props.handleAdjustTotal}
              handleSaveVariances={props.handleSaveVariances}
              handleInTransitClick={props.handleInTransitClick}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default StocktakeLineModal;
