import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// react-bootstrap components
import {
    Badge,
    Button,
    Card,
    Form,
    Navbar,
    Nav,
    Container,
    Col,
    Row,
} from "react-bootstrap";

function LoginPage() {
    const history = useHistory();

    const dispatch = useDispatch();

    // ### STATE ###
    const [cardClasses, setCardClasses] = useState("card-hidden");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [google2FAPin, setGoogle2FAPin] = useState("");
    const [isValidation, setIsValidation] = useState(false);

    // ### HANDLERS ###

    const onLogin = async () => {
        const auth = {
            Username: username,
            Password: password,
            Google2FAPin: google2FAPin,
        };
        try {
            const response = await fetch(`/account/login`, {
                method: "post",
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"],
                ],
                body: JSON.stringify(auth),
            });
            const login = await response.json();

            if (login?.id) {
                localStorage.setItem("userId", login.id);
                localStorage.setItem("userToken", login.userToken);
                localStorage.setItem("username", login.username);
                localStorage.setItem("permissions", login.permissions); //todo: move from local storage
                localStorage.setItem("AppName", login.appName); //todo: move from local storage
                history.push("/manage-products");
            } else {
                setIsValidation(true);
            }
        } catch (err) {
            setIsValidation(true);
        }
    };

    const onKeyDown = (e) => {
        if (e.keyCode === 13) onLogin();
    };

    // ### HOOKS ###

    useEffect(() => {
        setTimeout(function () {
            setCardClasses("");
        }, 1000);
    });

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleMouseDown = () => {
        setShowPassword(true); // Show the password while the mouse is down
    };

    const handleMouseUp = () => {
        setShowPassword(false); // Revert to hidden when the mouse is released
    };

    return (
        <>
            <div
                className="full-page section-image"
                data-color="dark"
                style={{
                    backgroundImage:
                        "url(" + require("assets/img/background.jpg").default + ")",
                }}
            >
                <div className="content d-flex align-items-center p-0">
                    <Container>
                        <Col className="mx-auto" lg="4" md="8">
                            <Form action="" className="form" method="">
                                <Card className={"card-login " + cardClasses}>
                                    <Card.Header>
                                        <div className="login-img text-center pt-2">
                                            <img
                                                src={require("assets/img/logo.png").default}
                                                alt="aldrich-logo"
                                            />
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Body>
                                            <Form.Group>
                                                <Row>
                                                    <label>Phone Number</label>
                                                    <Col md="12" className="p-0">
                                                        <Form.Control
                                                            placeholder="Phone Number"
                                                            type="text"
                                                            value={username}
                                                            on
                                                            onChange={(e) => setUsername(e.target.value)}
                                                            onKeyDown={(e) => onKeyDown(e)}
                                                        ></Form.Control>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group>
                                                <Row className="pt-3">
                                                    <label>Password</label>
                                                    <Col md="11" className="p-0 m-0">
                                                        <Form.Control
                                                            placeholder="Password"
                                                            type={showPassword ? "text" : "password"}
                                                            value={password}
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            onKeyDown={(e) => onKeyDown(e)}
                                                        ></Form.Control>
                                                    </Col>
                                                    <Col md="1" className="p-0 m-0">
                                                        <Button
                                                            className="password-btn btn-outline-secondary"
                                                            onMouseDown={handleMouseDown}
                                                            onMouseUp={handleMouseUp}
                                                            onMouseLeave={handleMouseUp} // Revert if mouse leaves the button
                                                        >
                                                            {showPassword ? (
                                                                <i className="password-icon fas fa-eye-slash"></i>
                                                            ) : (
                                                                <i className="password-icon fas fa-eye"></i>
                                                            )}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            {/*<Form.Group>*/}
                                            {/*    <Row className="pt-3">*/}
                                            {/*        <label>2FA</label>*/}
                                            {/*        <Col md="12" className="p-0">*/}
                                            {/*            <Form.Control*/}
                                            {/*                placeholder="2fa"*/}
                                            {/*                type="text"*/}
                                            {/*                length="6"*/}
                                            {/*                value={google2FAPin}*/}
                                            {/*                onChange={(e) => setGoogle2FAPin(e.target.value)}*/}
                                            {/*                onKeyDown={(e) => onKeyDown(e)}*/}
                                            {/*            ></Form.Control>*/}
                                            {/*        </Col>*/}
                                            {/*    </Row>*/}
                                            {/*</Form.Group>*/}
                                        </Card.Body>
                                    </Card.Body>
                                    <Card.Footer className="ml-auto mr-auto text-center">
                                        <h5>
                                            Forgot Password?{" "}
                                            <a href="/auth/reset-password">Click here</a>
                                        </h5>
                                        <Button
                                            className="btn-wd"
                                            variant="primary"
                                            onClick={() => onLogin()}
                                        >
                                            Login
                                        </Button>
                                        {isValidation && (
                                            <h5 className="text-danger">
                                                Incorrect username or password.
                                            </h5>
                                        )}
                                    </Card.Footer>
                                </Card>
                            </Form>
                        </Col>
                    </Container>
                </div>
                <div
                    className="full-page-background"
                    style={{
                        backgroundImage:
                            "url(" + require("assets/img/background.jpg").default + ")",
                    }}
                ></div>
            </div>
        </>
    );
}

export default LoginPage;
