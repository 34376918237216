import React, { useState } from "react";
import {
  OverlayTrigger,
  Table,
  Card,
  Col,
  Row,
  Button,
  Form,
  Tooltip,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";

const StocktakeTable = (props) => {
  const returnTableData = () => {
    return props.data.map((stocktake, index) => {
      if (stocktake === null || stocktake === undefined) return;
      const {
        SKU,
        id,
        locationId,
        dateOpened,
        dateCompleted,
        dateClosed,
        statusId,
        userId,
        status,
        brandId,
        scanned,
        variance,
        gender,
        startingStockOnHand,
        stocktakeNumber,
      } = stocktake;

      const getLocationName = () => {
        const location = props.locations.find(
          (location) => location.id === locationId
        );
        return location ? location.value : null;
      };

      const getUserName = () => {
        const user = props.users.find((user) => user.id === userId);
        return user ? user.value : null;
      };

      return (
        <tr key={index}>
          <td>
            <a href="#id" onClick={() => props.getStocktakeLine(id)}>
              {id}
            </a>
          </td>
          <td>{getLocationName()}</td>
          <td>{stocktakeNumber}</td>
          <td>{getUserName()}</td>
          <td>{gender}</td>
          <td>{status}</td>
          <td>{startingStockOnHand}</td>
          <td>{scanned}</td>
          <td>{variance}</td>
          <td>
            <OverlayTrigger
              href="#pablo"
              onClick={(e) => e.preventDefault()}
              overlay={<Tooltip id="tooltip-255158527">Remove Line</Tooltip>}
            >
              <Button
                className="btn-link btn-xs btn-action"
                disabled={status !== "Open"}
                variant="danger"
                onClick={() => props.handleDeleteStocktake(id)}
              >
                <i className="fas fa-times"></i>
              </Button>
            </OverlayTrigger>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title
            as="h4"
            className="d-flex justify-content-between align-items-center"
          >
            Stocktake
            <div className="d-flex">
              <Button
                className="btn-fill"
                variant="primary"
                onClick={() => props.handleShowStocktakeAnalysisModal()}
              >
                Stocktake Analysis
              </Button>
              <Button
                className="btn-fill"
                variant="primary"
                // will need to loop to get only true values from the checked items
                onClick={() => props.handleShowCreateStocktakeModal()}
              >
                Create Stocktake
              </Button>
            </div>
          </Card.Title>
        </Card.Header>
        <Card.Body className="table-responsive p-0">
          {props.data && props.data.length == 0 && !props.isLoading && (
            <p
              style={{ height: 550 }}
              className="d-flex justify-content-center align-items-center text-center"
            >
              No data found.
            </p>
          )}
          {props.data && props.data.length > 0 && !props.isLoading && (
            <Col md="12">
              <InfiniteScroll
                dataLength={props.data.length}
                next={props.updateData}
                hasMore={true}
                height={650}
                loader={
                  props.data.length >= props.take ? (
                    <h6>Loading more data...</h6>
                  ) : (
                    ""
                  )
                }
              >
                <Table className="table-hover table-striped w-full">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Location</th>
                      <th>Stocktake Number</th>
                      <th>Opened By</th>
                      <th>Gender</th>
                      <th>Status</th>
                      <th>Stock</th>
                      <th>Scanned</th>
                      <th>Variance</th>
                      <th style={{ width: "20px" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>{returnTableData()}</tbody>
                </Table>
              </InfiniteScroll>
            </Col>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default StocktakeTable;
