import React, { useState, useEffect } from "react";
//import Moment from 'react-moment';
// react component used to create charts
import { showSweetAlert } from "components/Shared/CustomAlert";
// react-bootstrap components
import { Card, Form, Container, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import UserTable from "../../components/UserManagement/UserTable";
import UserPage from "../../components/UserManagement/UserPage";
import CustomModal from "../../components/Shared/CustomModal";

import { ADMIN_USERMANAGEMENT_VIEW } from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";

function UserManagement() {
    const user = useSelector((state) => state.user);
    // ### STATE ###
    const [error, setError] = useState({
        username: "",
        email: "",
        phoneNumber: "",
        role: "",
        firstName: "",
        lastName: "",
        location: "",
    });

    const [roles, setRoles] = useState([]);
    const [locations, setLocations] = useState([]);
    const [data, setData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [userId, setUserId] = useState(0);
    const [selectedUser, setSelectedUser] = useState({});
    const [isUsername, setUsername] = useState(false);
    const [isEmail, setIsEmail] = useState(false);
    const [isPhoneNumber, setIsPhoneNumber] = useState(false);

    const [showAddEditModal, setShowAddEditModal] = useState(false);

    //hooks
    const handleCloseAddEditModal = () => setShowAddEditModal(false);

    // ### HANDLERS ###

    const clearAddUserValidation = () => {
        setError({
            username: "",
            email: "",
            phoneNumber: "",
            role: "",
            firstName: "",
            lastName: "",
            location: "",
        });
    };

    const handleAdd = () => {
        clearAddUserValidation();
        setUserId(0);
        setSelectedUser({
            id: 0,
            username: "",
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            roleId: 0,
            roleName: "",
            locationId: 0,
            locationName: "",
            permissions: [],
            avatar: "",
            appName: "",
        });
        setShowAddEditModal(true);
        setIsEdit(true);
    };
    const handleShowUser = async (id) => {
        clearAddUserValidation();
        try {
            let url = `/user/getsingle?id=${id}&userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const user = await response.json();

            setUserId(user.id);
            setSelectedUser(user);
            setShowAddEditModal(true);
            setIsEdit(false);
        } catch (err) { }
    };

    const handleEdit = async () => {
        try {
            setIsEdit(true);
        } catch (err) { }
    };

    const handleShowSave = () => {
        showSweetAlert("warning", `Are you sure to save the user?`, () => {
            hideSave();
        });
    };

    const hideSave = () => {
        handleSave();
    };

    const handleChange = (name, value) => {
        if (name == "roleId") {
            error.role = "";
        }
        if (name == "locationId") {
            error.location = "";
        }

        if (name === "phoneNumber") {
            value = value.replace(/[^\d+]/g, ""); // Remove all characters except digits and +
        }
        console.log("here")
        setSelectedUser({
            ...selectedUser,
            [name]: value,
        });
        validateInput(name, value);
    };

    console.log("selectedUser", selectedUser)

    // Apply validation in Input Feilds
    const validateInput = (name, value) => {
        setError((prev) => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "username":
                    if (!value) {
                        stateObj[name] = "Please enter a username";
                    } else if (isUsername) {
                        stateObj[name] = "Username Exists";
                    }
                    break;

                // only show the error if the user has not entered an email or phone number
                case "email":
                    if (isEmpty(selectedUser.phoneNumber) && isEmpty(value)) {
                        stateObj[name] = "Please enter an email or a phone number";
                    } else if (isEmail) {
                        stateObj[name] = "Email Exists";
                    } else {
                        // Clear the error for both email and phoneNumber if either has a value
                        stateObj["email"] = "";
                        stateObj["phoneNumber"] = "";
                    }
                    break;

                case "phoneNumber":
                    if (isEmpty(selectedUser.email) && isEmpty(value)) {
                        stateObj["email"] = "Please enter a phone number or email";
                    } else if (isPhoneNumber) {
                        stateObj[name] = "Phone Number Exists";
                    } else {
                        // Clear the error for both email and phoneNumber if either has a value
                        stateObj["email"] = "";
                        stateObj["phoneNumber"] = "";
                    }
                    break;

                case "roleId":
                    if (!value) {
                        stateObj[name] = "Please select a role";
                    }
                    break;
                case "lastName":
                    if (!value) {
                        stateObj[name] = "Please enter the last Name";
                    }
                    break;

                case "firstName":
                    if (!value) {
                        stateObj[name] = "Please enter the first Name";
                    }
                    break;
                case "location":
                    if (!value) {
                        stateObj[name] = "Please select a Location";
                    }
                    break;
                default:
                    break;
            }

            return stateObj;
        });
    };

    const isEmpty = (value) => {
        return value === null || value === undefined || value.trim() === "";
    };

    //Handle save for Add and Edit Users
    const handleSave = async () => {
        const newErrors = {};

        if (selectedUser.roleId === 0) {
            newErrors.role = "Please select a role";
        }
        if (isEmpty(selectedUser.username)) {
            newErrors.username = "Please enter a username";
        }
        if (isEmpty(selectedUser.lastName)) {
            newErrors.lastName = "Please enter a last Name";
        }
        if (isEmpty(selectedUser.firstName)) {
            newErrors.firstName = "Please enter a first Name";
        }
        if (selectedUser.locationId === 0) {
            newErrors.location = "Please enter a location";
        }
        if (selectedUser.email === "" && selectedUser.phoneNumber === "") {
            newErrors.email = "An email or a phone number is required";
            newErrors.phoneNumber = "An email or a phone number is required";
        }

        // set the errors and return if any before checking for duplicate usernames etc
        setError(newErrors);
        // if any errors then return and dont save
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        try {
            // Check Username & Email Exists & Phone Number Exists
            if (userId === 0 && selectedUser.username != "") {
                const resUsername = await fetch(
                    `/user/usernameexists?username=${selectedUser.username}`
                );
                const isUsernameExists = await resUsername.json();
                if (isUsernameExists === true) {
                    setUsername(true);
                    // error.username = "Username Exists";
                    newErrors.username = "Username Exists";
                }
                if (selectedUser.email != "") {
                    const resEmail = await fetch(
                        `/user/emailexists?email=${selectedUser.email}`
                    );
                    const isEmailExists = await resEmail.json();
                    if (isEmailExists === true) {
                        setIsEmail(true);
                        // error.email = "Email Exists";
                        newErrors.email = "Email Exists";
                    }
                }
                if (selectedUser.phoneNumber != "") {
                    const resPhone = await fetch(
                        `/user/phoneexists?phoneNumber=${selectedUser.phoneNumber}`
                    );
                    const isPhoneExists = await resPhone.json();
                    if (isPhoneExists === true) {
                        setIsPhoneNumber(true);
                        // error.phoneNumber = "Phone Number Exists";
                        newErrors.phoneNumber = "Phone Number Exists";
                    }
                }
            }

            // set the erros if any from duplicate usernames etc
            setError(newErrors);
            // if any errors then return and dont save
            if (Object.keys(newErrors).length > 0) {
                return;
            }

            // Add User
            if (userId === 0) {
                const response = await fetch(
                    `/user/add?userId=${localStorage.getItem(
                        "userId"
                    )}&userToken=${localStorage.getItem("userToken")}`,
                    {
                        method: "post",
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"],
                        ],
                        body: JSON.stringify(selectedUser),
                    }
                );
                const body = await response.json();
                if (body) {
                    showSweetAlert("saveSuccess", `User`);
                    await fetchData();
                    handleCloseAddEditModal();
                    return;
                } else {
                    showSweetAlert("serverError");
                }
            }

            // Edit User
            if (userId !== 0) {
                console.log("selectedUser", selectedUser)
                // if (error.username === "" && error.email === "" && error.role === "") {
                const response = await fetch(
                    `/user/update?id=${userId}&userId=${localStorage.getItem(
                        "userId"
                    )}&userToken=${localStorage.getItem("userToken")}`,
                    {
                        method: "put",
                        headers: [
                            ["Content-Type", "application/json"],
                            ["Content-Type", "text/plain"],
                        ],
                        body: JSON.stringify(selectedUser),
                    }
                );
                const body = await response.json();
                if (body) {
                    showSweetAlert("saveSuccess", `User`);
                    await fetchData();
                    handleCloseAddEditModal();
                } else {
                    showSweetAlert("serverError");
                }
                // }
            }
        } catch (err) { }
    };

    const handleCancel = () => {
        setIsEdit(false);
        hideAlert();
    };

    const handleQr = async (id) => {
        try {
            const response = await fetch(
                `/user/generatenewqr?id=${id}&userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}`,
                { method: "post" }
            );
            const data = await response.json();

            if (data === true) {
                showSweetAlert("saveSuccess", `User`);
            }

            if (body) {
                showSweetAlert("saveSuccess", `User`);
                await fetchData();
            }

            await fetchData();
        } catch (err) { }
    };

    const handlePin = async (id) => {
        try {
            const response = await fetch(
                `/user/generatenewpin?id=${id}&userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}`,
                { method: "post" }
            );
            const data = await response.json();

            if (data === true) {
                showSweetAlert("saveSuccess", `User`);
            }

            if (body) {
                showSweetAlert("saveSuccess", `User`);
                await fetchData();
            }

            await fetchData();
        } catch (err) { }
    };

    const handleDelete = (id) => {
        showSweetAlert("deleteWarning", `user`, () => {
            successDelete(id);
        });
    };
    const successDelete = async (id) => {
        try {
            const response = await fetch(
                `/user/delete?id=${id}&userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}`,
                { method: "delete" }
            );
            showSweetAlert("deleteSuccess", `User`);
            await fetchData();
        } catch (err) { }
    };

    const hideAlert = () => { };

    // ### DATA FETCHES ###

    const fetchRoles = async () => {
        try {
            let url = `/user/getroles?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();

            setRoles(body);
        } catch (err) { }
    };
    const fetchLocations = async () => {
        try {
            let url = `/location/getdropdownlist?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();
            const data = body?.map((item) => {
                return { id: item.id, value: item.name };
            });

            setLocations(data);
        } catch (err) { }
    };
    const fetchData = async () => {
        try {
            let url = `/user/getall?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const users = await response.json();

            setData(users);
        } catch (err) { }
    };

    // ### HOOKS ###

    useEffect(() => {
        fetchRoles();
        fetchLocations();
        fetchData();
    }, []);

    return (
        <>
            {alert}

            <CustomModal
                show={showAddEditModal}
                handleClose={handleCloseAddEditModal}
                title={userId === 0 ? "Add User" : "Edit User"}
                backdrop="static"
            >
                <UserPage
                    userId={userId}
                    user={selectedUser}
                    roles={roles}
                    locations={locations}
                    handleChange={handleChange}
                    handleSave={handleSave}
                    handleCancel={handleCancel}
                    handleQr={handleQr}
                    handlePin={handlePin}
                    validateInput={validateInput}
                    error={error}
                    handleShowSave={handleShowSave}
                    isEdit={isEdit}
                    handleEdit={handleEdit}
                />
            </CustomModal>

            {getPermission(user.permissions, ADMIN_USERMANAGEMENT_VIEW) && (
                <Container fluid>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Container style={{ height: 750 }} fluid>
                                    <Row className="elysium-h100">
                                        <UserTable
                                            data={data}
                                            handleAdd={handleAdd}
                                            handleShow={handleShowUser}
                                            handleDelete={handleDelete}
                                        />
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            )}
            {!getPermission(user.permissions, ADMIN_USERMANAGEMENT_VIEW) && (
                <Container className="mt-4 ms-4">
                    You are not authorized to view this page
                </Container>
            )}
        </>
    );
}

export default UserManagement;
