import React, { useState, useEffect, useMemo, useRef } from "react";
import moment from "moment";
import CustomModal from "components/Shared/CustomModal";

import {
  Card,
  Container,
  Row,
  Col,
  Button,
  Form,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useSelector } from "react-redux";

import DateRange from "../../components/Shared/DateRange";
import CustomSelect from "../../components/Shared/CustomSelect";
import PurchaseOrderTable from "components/PurchaseOrder/PurchaseOrderTable";
import PurchaseOrderModal from "components/PurchaseOrder/PurchaseOrderModal";
import AddEditPurchaseOrderLineModal from "components/PurchaseOrder/AddEditPurchaseOrderLineModal";
import { showSweetAlert } from "components/Shared/CustomAlert";
import Loading from "components/Shared/Loading";
import AddEditPurchaseOrderModal from "components/PurchaseOrder/AddEditPurchaseOrderModal";
import { propTypes } from "react-bootstrap/esm/Image";
import animationData from "../../assets/animations/orange-square.json";
import ItemCard from "../../components/ItemCard/ItemCard";
import InventoryModal from "../../components/ItemCard/InventoryModal";

import {
  PURCHASEORDER_VIEW,
  PURCHASEORDER_EDIT,
  PURCHASEORDER_DELETE,
} from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";

function PurchaseOrder() {
  const user = useSelector((state) => state.user);

  // ### STATE ###
  const [originPurchaseOrder, setOriginPurchaseOrder] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showPurchaseOrderLineModal, setPurchaseOrderLineModal] =
    useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  const handleClosePurchaseOrderLineModal = () =>
    setPurchaseOrderLineModal(false);
  const handleShowPurchaseOrderLineModal = () =>
    setPurchaseOrderLineModal(true);
  const [status, setStatus] = useState([]);
  const [statusId, setStatusId] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const [itemCardSelectedTab, setItemCardSelectedTab] = useState("Detail");

  const [isLoading, setIsLoading] = useState(true);
  const [PurchaseOrderLine, setPurchaseOrderLine] = useState([]);
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(500);
  const defaultDate = new Date();
  const [dateRange, setDateRange] = useState([
    defaultDate.setMonth(defaultDate.getMonth() - 3),
    new Date(),
  ]);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierId, setSupplierId] = useState(0);
  const [season, setSeason] = useState([]);
  const [brands, setBrands] = useState([]);
  const [seasonId, setseasonId] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [purchaseOrderSearch, setPurchaseOrderSearch] = useState("");
  const [purchaseOrderLineSearch, setPurchaseOrderLineSearch] = useState("");
  const [orderBy, setOrderBy] = useState("DateCreated");
  const [descending, setDescending] = useState(true);
  const [originData, setOriginData] = useState([]);
  const [data, setData] = useState([]);
  const [currentEstimatedDeliveryDate, setCurrentEstimatedDeliveryDate] =
    useState([new Date()]);

  const [purchaseOrderLineChange, setPurchaseOrderLineChange] = useState([]);
  const [products, setProducts] = useState([]);
  const [purchaseOrderLineId, setPurchaseOrderLineId] = useState(0);
  const [editPurchaseOrderLineId, setEditPurchaseOrderLineId] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState({
    productId: "",
    quantity: "",
  });
  const [quantityValidate, setQuantityValidate] = useState(true);
  const [isImportLoading, setIsImportLoading] = useState(false);

  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState([]);

  const [selectedProductId, setSelectedProductId] = useState(0);
  const [selectedInventory, setSelectedInventory] = useState([]);
  const [showItemCardModal, setShowItemCardModal] = useState(false);
  const [showInventoryModal, setShowInventoryViewModal] = useState(false);

  const fileInputRef = useRef(null);
  let files = "";
  const [validation, setvalidation] = useState({
    purchaseOrderNumber: "",
    brandId: "",
    seasonId: "",
    estimatedDeliveryDate: "",
  });
  // ### HANDLERS ###
  const [selectedDate, setSelectedDate] = useState(null);
  const [locations, setLocations] = useState([]);

  const handlePurchaseOrderSearchChange = (value) => {
    setPurchaseOrderSearch(value);
  };

  const handlePurchaseOrderLineSearchChange = (value) => {
    setPurchaseOrderLineSearch(value);
  };

  const clearPurchaseOrderValidation = () => {
    setvalidation({
      purchaseOrderNumber: "",
      brandId: "",
      seasonId: "",
      estimatedDeliveryDate: "",
    });
  };

  const clearPurchaseOrderLineValidation = () => {
    setError({
      productId: "",
      quantity: "",
    });
  };

  const handleDateChange = (date) => {
    validation.estimatedDeliveryDate = "";
    setSelectedPurchaseOrder({
      ...selectedPurchaseOrder,
      ["estimatedDeliveryDate"]: date,
    });
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      const binaryString = event.target.result;
      const base64String = btoa(binaryString);
      files = base64String;
      saveFile();
    };
    reader.readAsBinaryString(file);
  };

  const handleSubmit = () => {
    fileInputRef.current.click();
  };

  const handleExportTemplate = async () => {
    setIsLoading(true);
    try {
      const url = `PurchaseOrder/GetExportTemplate?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url);
      const base64String = await response.text();
      const binaryData = Buffer.from(base64String, "base64");
      const blob = new Blob([binaryData], {
        type: "application/vnd.ms-excel.sheet.macroEnabled.12",
      });

      // Save file
      const Newurl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = Newurl;
      link.setAttribute("download", "Export.xlsm");
      link.click();
      setIsLoading(false);
    } catch (e) {
      showSweetAlert("serverError");
    }
  };

  const handleGetExportExcel = async (id, name) => {
    setIsLoading(true);
    try {
      const url = `PurchaseOrder/GetExportExcel?id=${id}&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url);
      const base64String = await response.text();
      const binaryData = Buffer.from(base64String, "base64");
      const blob = new Blob([binaryData], {
        type: "application/vnd.ms-excel.sheet.macroEnabled.12",
      });

      // Save file
      const Newurl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = Newurl;
      link.setAttribute("download", name + ".xlsm");
      link.click();
      setIsLoading(false);
    } catch (e) {
      showSweetAlert("serverError");
    }
  };

  const validateInput = (name, value) => {
    setvalidation((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "purchaseOrderNumber":
          if (!value) {
            stateObj[name] = "Please enter an purchaseOrder Number.";
          }
          break;
        case "brandId":
          if (!value) {
            stateObj[name] = "Please select a brand.";
          }
          break;
        case "seasonId":
          if (!value) {
            stateObj[name] = "Please select a season.";
          }
          break;
        case "estimatedDeliveryDate":
          if (!value) {
            stateObj[name] = "Please enter an estimated delivery date.";
          }
          break;
        case "locationId":
          if (!value) {
            stateObj[name] = "Please select a location.";
          }
          break;
        default:
          break;
      }
      return stateObj;
    });
  };

  const saveFile = async () => {
    // Send the base64 string to the API using fetch or axios
    try {
      setIsImportLoading(true);
      const payload = files;
      const response = await fetch(
        `PurchaseOrder/Import?userId=${localStorage.getItem(
          "userId"
        )}&userToken=${localStorage.getItem("userToken")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const body = await response.json();
      if (response.status === 200) {
        showSweetAlert("saveSuccess", `Purchase Order`);
      } else {
        if (
          body ==
          "A purchase order reference as specified in this document already exists."
        ) {
          showSweetAlert(
            "warningCustomMessage",
            `This purchase order reference already exists. Importing this sheet will update the allocations against this purchase order. Are you sure you wish to continue?`,
            () => {
              handlePurchaseOrderReallocation();
            }
          );
        } else {
          showSweetAlert(
            "warningOkOnly",
            body
              .split("\n")
              .map((line) => `<div className="py-2">${line}</div>`)
              .join("<br />")
          );
        }
      }
    } catch {
    } finally {
      setIsImportLoading(false);
      fetchPurchaseOrderData();
    }
  };

  const handlePurchaseOrderReallocation = () => {
    reallocatePurchaseOrder();
  };

  const reallocatePurchaseOrder = async () => {
    // Send the base64 string to the API using fetch or axios
    try {
      setIsImportLoading(true);
      const payload = files;
      const response = await fetch(
        `PurchaseOrder/ImportReallocation?userId=${localStorage.getItem(
          "userId"
        )}&userToken=${localStorage.getItem("userToken")}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
      const body = await response.json();
      if (response.status === 200) {
        if (body == "") {
          showSweetAlert("saveSuccess", `Purchase Order`);
        } else {
          showSweetAlert(
            "saveSuccessCustomMessage",
            `Import complete with the following errors: <br /><br /> ${body}`
          );
        }
      } else {
        showSweetAlert("serverError");
      }
    } catch {
    } finally {
      setIsImportLoading(false);
      fetchPurchaseOrderData();
    }
  };

  const handlePurchaseOrderCancel = () => {
    handleCloseAddModal();

    if (selectedPurchaseOrder.id != 0) {
      setSelectedPurchaseOrder({
        ...selectedPurchaseOrder,
        ["estimatedDeliveryDate"]: currentEstimatedDeliveryDate,
      });
      handleShowModal();
    }
  };

  const handleDatesChange = (dates) => {
    setDateRange(dates);
    if (dates[0] && dates[1]) {
      const filterData = originData.filter((item) => {
        return moment(item.timestamp).isBetween(
          moment(dates[0], "YYYY-MM-DDTHH:mm:ssZ").format(
            "YYYY-MM-DDTHH:mm:ssZ"
          ),
          moment(dates[1], "YYYY-MM-DDTHH:mm:ssZ").format(
            "YYYY-MM-DDTHH:mm:ssZ"
          ),
          null,
          "[]"
        );
      });
      setData(filterData);
    }
  };

  const handleLineChange = (name, value) => {
    let item = {
      ...purchaseOrderLineChange,
      [name]: value,
    };
    setPurchaseOrderLineChange(item);
    validatePurchaseOrderLineInput(name, value);
  };

  const handleProductLineChange = async (name, event) => {
    // if productId is changed (Add only) autofill cost price
    if (name == "productId") {
      validatePurchaseOrderLineInput(name, event.value);
      let cost = await fetchProduct(event.value);
      let item = {
        ...purchaseOrderLineChange,
        [name]: event.value,
        costPrice: cost,
      };
      setPurchaseOrderLineChange(item);
    }
  };

  const validatePurchaseOrderLineInput = (name, value) => {
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "productId":
          if (!value) {
            stateObj[name] = "Please select a product.";
          }
          break;
        case "quantity":
          if (value <= 0) {
            stateObj[name] = "Quantity must be greater then 0.";
          } else if (value < purchaseOrderLineChange.allocated) {
            stateObj[name] =
              purchaseOrderLineChange.allocated +
              " have already been allocated. You cannot set the quantity lower than this.";
          }
          break;
        default:
          break;
      }

      return stateObj;
    });
  };

  const handleAddPurchaseOrderLine = () => {
    clearPurchaseOrderLineValidation();
    setIsEdit(false);
    setPurchaseOrderLineChange({
      quantity: 0,
      costPrice: 0,
      discountPercentage: 0,
      discount: 0,
      purchaseOrderId: selectedPurchaseOrder.id,
      locationId: 0,
      locationName: "",
      SeasonId: 0,
      SeasonName: "",
      SupplierBarcode: 0,
      BrandCode: "",
      productId: 0,
      productName: "",
      tax: 0,
      sku: "",
      size: "",
      colour: "",
      totalPrice: 0,
      statusId: 0,
      warehouseAllocation: "",
      statusName: "",
      allocations: [],
    });
    handleShowPurchaseOrderLineModal();
  };

  const handleAllocationChange = (event, index) => {
    const { name, value } = event.target;
    const allocations = [...purchaseOrderLineChange.allocations];
    allocations[index]["quantity"] = parseInt(value);
    const quantity = parseInt(purchaseOrderLineChange.quantity);
    if (allocations[index]["quantity"] <= quantity) {
      error.quantity = "";
      setQuantityValidate(true);
    } else {
      error.quantity = "Not enough to fullfil allocation";
      setQuantityValidate(false);
    }
    setPurchaseOrderLine({ ...purchaseOrderLineChange, allocations });
  };

  const handleSearchChange = (value) => {
    const filterData = originPurchaseOrder?.filter((item) =>
      item.productName.toLowerCase().includes(value.toLowerCase())
    );
    setSearch(value);
    setPurchaseOrderLine(filterData);
  };
  var isArchived = false;
  const Check = (event) => {
    isArchived = event.target.checked;
    fetchSeason();
    fetchBrands();
    fetchPurchaseOrderData();
  };

  const handleDropDownChange = (label, id, idName, name) => {
    // handle the location change - need to get the name and id
    if (name === "location") {
      // get the location name
      const selectedLocation = locations.filter((x) => x.id === id);
      setSelectedPurchaseOrder({
        ...selectedPurchaseOrder,
        locationName: selectedLocation[0].value,
        locationId: selectedLocation[0].id,
      });
    } else {
      setSelectedPurchaseOrder({
        ...selectedPurchaseOrder,
        [name]: label,
        [idName]: id,
      });
    }

    validateInput(idName, id);
  };

  const handleChangePurchaseOrder = (name, value) => {
    setSelectedPurchaseOrder({
      ...selectedPurchaseOrder,
      [name]: value,
    });
    validateInput(name, value);
  };

  const handleAddModelPurchaseOrder = () => {
    clearPurchaseOrderValidation();

    setSelectedPurchaseOrder({
      id: 0,
      purchaseOrderNumber: "",
      estimatedDeliveryDate: new Date(),
      supplierId: 0,
      supplierName: "",
      supplierAddress: "",
      buyerName: "",
      buyerAddress: "",
      shippingAddress: "",
      userId: localStorage.getItem("userId"),
      userFullName: localStorage.getItem("username"),
      costPrice: 0,
      tax: 0,
      locationId: null,
      locationName: "",
      discount: 0,
      total: 0,
      totalQuantity: 0,
      seasonId: 0,
      season: "",
      statusId: 0,
      statusName: "",
      brandId: "",
      brandName: "",
      createdDate: new Date(),
    });

    handleShowAddModal();
  };

  const handleEditModelPurchaseOrder = () => {
    clearPurchaseOrderValidation();
    handleCloseModal();
    handleShowAddModal();
  };

  const handleEditPurchaseOrderLine = (id) => {
    clearPurchaseOrderLineValidation();
    setEditPurchaseOrderLineId(id);
    setIsEdit(true);
    let PurchaseOrderValue = PurchaseOrderLine.filter((x) => x.id == id);
    setPurchaseOrderLineChange(...PurchaseOrderValue);
    handleShowPurchaseOrderLineModal();
  };

  const handleShowInventoryModal = (item) => {
    setSelectedInventory(item);
    setShowInventoryViewModal(true);
    setShowItemCardModal(false);
  };

  const handleHideInventoryModal = () => {
    setShowInventoryViewModal(false);
    setShowItemCardModal(true);
  };

  // ### DATA FETCHES ##
  const handleAddPurchaseOrder = () => {
    showSweetAlert(
      "warning",
      `Are you sure you want to add this purchase order?`,
      () => {
        handleSavePurchaseOrder();
      }
    );
  };
  const handleUpdatePurchaseOrder = () => {
    showSweetAlert(
      "warning",
      `Are you sure you want to update the purchase order?`,
      () => {
        handleSavePurchaseOrder();
      }
    );
  };

  const isEmpty = (value) => {
    if (value === null || value === undefined) return true;
    if (typeof value === "string" && value.trim() === "") return true;
    return false;
  };

  const handleSavePurchaseOrder = async () => {
    let validationErrors = {};

    if (isEmpty(selectedPurchaseOrder.purchaseOrderNumber)) {
      validationErrors.purchaseOrderNumber =
        "Please enter the purchaseOrderNumber";
    }
    if (selectedPurchaseOrder.brandId <= 0) {
      validationErrors.brandId = "Please select the brand";
    }
    if (selectedPurchaseOrder.seasonId <= 0) {
      validationErrors.seasonId = "Please select the season";
    }
    if (isEmpty(selectedPurchaseOrder.estimatedDeliveryDate)) {
      validationErrors.estimatedDeliveryDate = "Please select the date";
    }
    if (selectedPurchaseOrder.locationId <= 0) {
      validationErrors.locationId = "Please select the location";
    }

    setvalidation(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    // if (
    //   selectedPurchaseOrder.purchaseOrderNumber === "" &&
    //   selectedPurchaseOrder.brandId <= 0 &&
    //   selectedPurchaseOrder.seasonId <= 0 &&
    //   selectedPurchaseOrder.estimatedDeliveryDate === ""
    // ) {
    //   validation.purchaseOrderNumber = "Please enter the purchaseOrderNumber";
    //   validation.brandId = "Please select the brand";
    //   validation.seasonId = "Please select the season";
    //   validation.estimatedDeliveryDate = "Please select the date";
    //   return;
    // } else {
    //   if (selectedPurchaseOrder.purchaseOrderNumber === "") {
    //     validation.purchaseOrderNumber = "Please enter the purchaseOrderNumber";
    //   }
    //   if (selectedPurchaseOrder.brandId === 0) {
    //     validation.brandId = "Please select the brand";
    //   }
    //   if (selectedPurchaseOrder.seasonId === 0) {
    //     validation.seasonId = "Please select the season";
    //   }
    //   if (selectedPurchaseOrder.estimatedDeliveryDate === "") {
    //     validation.estimatedDeliveryDate = "Please select the date";
    //   }
    // }

    try {
      if (selectedPurchaseOrder.id == 0) {
        let url = `PurchaseOrder/Add?userId=${localStorage.getItem(
          "userId"
        )}&userToken=${localStorage.getItem("userToken")}`;
        selectedPurchaseOrder.estimatedDeliveryDate = new Date(dateRange[0]);
        let response = await fetch(url, {
          method: "POST",
          headers: [
            ["Content-Type", "application/json"],
            ["Content-Type", "text/plain"],
          ],
          body: JSON.stringify(selectedPurchaseOrder),
        });

        if (response.status === 200) {
          showSweetAlert("saveSuccess", `Purchase Order`);
          fetchPurchaseOrderData();
          handleCloseAddModal();
        } else {
          showSweetAlert("serverError");
        }
      } else {
        let url = `PurchaseOrder/Update?userId=${localStorage.getItem(
          "userId"
        )}&userToken=${localStorage.getItem("userToken")}`;
        let response = await fetch(url, {
          method: "PUT",
          headers: [
            ["Content-Type", "application/json"],
            ["Content-Type", "text/plain"],
          ],
          body: JSON.stringify(selectedPurchaseOrder),
        });

        if (response.status == 200) {
          showSweetAlert("saveSuccess", `Purchase Order`);
          handleCloseAddModal();
          handleShowModal();
        } else {
          showSweetAlert("serverError");
        }
      }
    } catch (e) {
      showSweetAlert("serverError");
    }
  };

  // ### DATA FETCHES ##
  const fetchProductDropdown = async (brandId, seasonId) => {
    try {
      let url = `/Product/GetDropdownListWithSkuAndBarcode?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&includeArchived=${isArchived}&brandId=${brandId}&seasonId=${seasonId}`;
      const response = await fetch(url);
      const body = await response.json();
      const data = body?.map((item) => {
        return { id: item.id, value: item.name };
      });
      setProducts(data);
    } catch (e) {}
  };

  const fetchProduct = async (id) => {
    try {
      let url = `/Product/GetSingle?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
      const response = await fetch(url);
      const body = await response.json();
      return body.cost;
    } catch (e) {}
  };

  const fetchPurchaseOrderData = async () => {
    try {
      let from = new Date(dateRange[0]);

      let url = `/PurchaseOrder/getallpaged?skip=${skip}&take=${take}&brandId=${brandId}&seasonId=${seasonId}&statusId=${statusId}&from=${moment(
        from,
        "YYYY-MM-DD"
      ).format("YYYY-MM-DD")}&to=${
        dateRange[1] === null
          ? moment(from, "YYYY-MM-DD").format("YYYY-MM-DD")
          : moment(dateRange[1], "YYYY-MM-DD").format("YYYY-MM-DD")
      }&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&orderBy=${orderBy}&descending=${descending}`;

      const response = await fetch(url);
      const body = await response.json();
      setIsLoading(false);
      setOriginData(body);
      setData(body);
    } catch (err) {
      setIsLoading(false);
    }
  };

  useMemo(async () => {
    setPage(0);
    setOriginData([]);
    setData([]);
    setIsLoading(true);
    fetchPurchaseOrderData();
  }, [brandId, seasonId, dateRange, purchaseOrderSearch, statusId]);

  const fetchSeason = async () => {
    try {
      let seasonUrl = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem(
        "userId"
      )}&&userToken=${localStorage.getItem(
        "userToken"
      )}&&categoryId=4&&includeArchived=${isArchived}`;
      const seasonResponse = await fetch(seasonUrl);
      const seasonBody = await seasonResponse.json();
      const seasonData = seasonBody?.map((item) => {
        return { id: item.id, value: item.name };
      });
      setSeason(seasonData);
    } catch (err) {}
  };

  const fetchBrands = async () => {
    try {
      let brandUrl = `/CategoryValue/GetDropdownList?userId=${localStorage.getItem(
        "userId"
      )}&&userToken=${localStorage.getItem(
        "userToken"
      )}&&categoryId=2&&includeArchived=${isArchived}`;
      const brandResponse = await fetch(brandUrl);
      const brandBody = await brandResponse.json();
      const brandData = brandBody?.map((item) => {
        return { id: item.id, value: item.name };
      });
      setBrands(brandData);
    } catch (err) {}
  };
  const PurchaseOrderLines = (purchaseOrderId) => {
    handleShowModal();
    fetchPurchaseOrderLine(purchaseOrderId);
    fetchSelectedPurchaseOrder(purchaseOrderId);
  };

  const downloadPdf = (base64String, filename) => {
    const decodedData = Buffer.from(base64String, "base64").toString("binary");
    const dataArray = new Uint8Array(
      Array.from(decodedData, (char) => char.charCodeAt(0))
    );
    const blob = new Blob([dataArray], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };

  const handleGetExportPDF = async (id, name) => {
    try {
      setIsLoading(true);
      const url = `PurchaseOrder/GetExportPDF?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
      const response = await fetch(url);
      const base64String = await response.text();
      downloadPdf(base64String, name + ".pdf");
      setIsLoading(false);
    } catch (e) {
      showSweetAlert("serverError");
    }
  };

  const handleGetLabelPDF = async (id, name) => {
    try {
      setIsLoading(true);
      const url = `PurchaseOrder/GetProductLabelsPDF?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
      const response = await fetch(url);
      const base64String = await response.text();
      downloadPdf(base64String, name + "_labels.pdf");
      setIsLoading(false);
    } catch (e) {
      showSweetAlert("serverError");
    }
  };

  const handleDeletePurchaseOrder = (id) => {
    showSweetAlert(
      "warning",
      `Are you sure you want to delete this purchase order?`,
      () => {
        deletePurchaseOrder(id);
      }
    );
  };

  const deletePurchaseOrder = async (id) => {
    const url = `PurchaseOrder/Delete?userId=${localStorage.getItem(
      "userId"
    )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
    const response = await fetch(url, {
      method: "delete",
    });
    fetchPurchaseOrderData();
  };

  const handleClosePurchaseOrder = (id) => {
    showSweetAlert(
      "warning",
      `Are you sure you want to close this purchase order?`,
      () => {
        closePurchaseOrder(id);
      }
    );
  };
  const closePurchaseOrder = async (id) => {
    const url = `PurchaseOrder/ClosePurchaseOrder?userId=${localStorage.getItem(
      "userId"
    )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
    const response = await fetch(url, {
      method: "put",
    });
    fetchPurchaseOrderData();
  };

  const handleReopenPurchaseOrder = (id) => {
    showSweetAlert(
      "warning",
      `Are you sure you want to reopen this purchase order?`,
      () => {
        reopenPurchaseOrder(id);
      }
    );
  };
  const reopenPurchaseOrder = async (id) => {
    const url = `PurchaseOrder/ReopenPurchaseOrder?userId=${localStorage.getItem(
      "userId"
    )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
    const response = await fetch(url, {
      method: "put",
    });
    fetchPurchaseOrderData();
  };

  const handleSavePurchaseOrderLine = (values) => {
    showSweetAlert(
      "warning",
      `Are you sure you want to save this purchase order line?`,
      () => {
        handleSaveChangesPurchaseOrderLine(values);
      }
    );
  };

  const handleSaveChangesPurchaseOrderLine = async (values) => {
    try {
      const url = `/PurchaseOrderLine/Add?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url, {
        method: "POST",
        headers: [["Content-Type", "application/json"]],
        body: JSON.stringify(values),
      });

      if (response.status == 200) {
        showSweetAlert("saveSuccess", `Purchase Order Line`);
        handleClosePurchaseOrderLineModal();
        fetchPurchaseOrderLine(selectedPurchaseOrder.id);
      } else {
        showSweetAlert("errorCustomMessage", response.data);
        handleClosePurchaseOrderLineModal();
      }
    } catch (e) {
      showSweetAlert("serverError");
    }
  };
  const handleUpdatePurchaseOrderLine = (values) => {
    showSweetAlert(
      "warning",
      `Are you sure you want to update this purchase order line?`,
      () => {
        handleUpdateChangesPurchaseOrderLine(values);
      }
    );
  };

  const handleUpdateChangesPurchaseOrderLine = async (values) => {
    try {
      const url = `/PurchaseOrderLine/Update?id=${editPurchaseOrderLineId}&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}`;
      const response = await fetch(url, {
        method: "put",
        headers: [["Content-Type", "application/json"]],
        body: JSON.stringify(values),
      });

      if (response.status == 200) {
        showSweetAlert("saveSuccess", `Purchase Order Line`);
        handleClosePurchaseOrderLineModal();
        fetchPurchaseOrderLine(selectedPurchaseOrder.id);
      } else {
        const body = await response.json();
        showSweetAlert("errorCustomMessage", body);
        handleClosePurchaseOrderLineModal();
      }
    } catch (e) {}
  };

  const fetchPurchaseOrderLine = async (purchaseOrderId) => {
    setPurchaseOrderLineId(purchaseOrderId);
    try {
      let url = `/PurchaseOrderLine/GetAllPaged?skip=${skip}&take=${take}&createdByUserId=${seasonId}&userId=${localStorage.getItem(
        "userId"
      )}&purchaseOrderId=${purchaseOrderId}&search=${purchaseOrderLineSearch}&userToken=${localStorage.getItem(
        "userToken"
      )}&orderBy=${orderBy}&descending=${descending}`;
      const response = await fetch(url);
      const body = await response.json();
      setOriginPurchaseOrder(body);
      setPurchaseOrderLine(body);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    handleClosePurchaseOrderLineModal();
  };

  //const fetchSuppliers = async () => {
  //    try {
  //        let url = `/supplier/GetDropdownList?userId=${localStorage.getItem("userId")}&userToken=${localStorage.getItem("userToken")}&&includeArchived=${isArchived}`;
  //        const response = await fetch(url);
  //        const body = await response.json();
  //        const data = body?.map((item) => {
  //            return { id: item.id, value: item.name };
  //        });
  //        setSuppliers(data);
  //    } catch (err) {
  //    }
  //}
  const updateData = async () => {
    try {
      let url = `/PurchaseOrder/getallpaged?skip=${
        page * take + skip
      }&take=${take}&brandId=${brandId}&seasonId=${seasonId}&statusId=${statusId}&userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem(
        "userToken"
      )}&orderBy=${orderBy}&descending=${descending}`;
      const response = await fetch(url);
      const body = await response.json();
      setTimeout(() => {
        setOriginData(originData.concat(body));
        setData(data.concat(body));
        setPage(page + 1);
      }, 500);
    } catch (err) {}
  };

  const fetchSelectedPurchaseOrder = async (id) => {
    try {
      let url = `/PurchaseOrder/GetSingle?userId=${localStorage.getItem(
        "userId"
      )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
      const response = await fetch(url);
      const body = await response.json();
      setSelectedPurchaseOrder(body);
      fetchProductDropdown(body.brandId, body.seasonId);
      setCurrentEstimatedDeliveryDate(body.estimatedDeliveryDate);
    } catch (err) {}
  };
  const fetchStatusDropdownList = async () => {
    try {
      let Url = `/PurchaseOrder/GetStatusDropdownList?userId=${localStorage.getItem(
        "userId"
      )}&&userToken=${localStorage.getItem("userToken")}`;
      const Response = await fetch(Url);
      const Body = await Response.json();
      const data = Body?.map((item) => {
        return { id: item.id, value: item.name };
      });
      setStatus(data);
    } catch (err) {}
  };

  const handleGetItemCard = (id) => {
    setSelectedProductId(id);
    setShowItemCardModal(true);
    setShowModal(false);
  };

  const handleItemCardCloseModal = () => {
    setShowItemCardModal(false);
    setSelectedProductId(0);
    setShowModal(true);
    setItemCardSelectedTab("Detail");
  };

  const fetchLocations = async () => {
    try {
      let globalDropdownsUrl = `/CategoryValue/GetGlobalDropdowns?userId=${localStorage.getItem(
        "userId"
      )}&&userToken=${localStorage.getItem("userToken")}`;
      const globalDropdownsResponse = await fetch(globalDropdownsUrl);
      const globalDropdownsBody = await globalDropdownsResponse.json();
      const locationData = globalDropdownsBody?.locations?.map((item) => {
        return { id: item.id, value: item.name };
      });
      setLocations(locationData);
    } catch (error) {
      console.log(error);
    }
  };

  // ### HOOKS ###
  useEffect(() => {
    fetchPurchaseOrderLine(purchaseOrderLineId);
    fetchSeason();
    fetchStatusDropdownList();
    fetchBrands();
    fetchLocations();
  }, [purchaseOrderLineSearch]);

  return (
    <>
      {showPurchaseOrderLineModal == false && (
        <CustomModal show={showModal} handleClose={handleCloseModal} title="">
          <PurchaseOrderModal
            PurchaseOrderLineData={PurchaseOrderLine}
            selectedPurchaseOrder={selectedPurchaseOrder}
            isLoading={isLoading}
            handleEditModelPurchaseOrder={handleEditModelPurchaseOrder}
            search={purchaseOrderLineSearch}
            handleSearchChange={handlePurchaseOrderLineSearchChange}
            handleAddPurchaseOrderLine={handleAddPurchaseOrderLine}
            handleEditPurchaseOrderLine={handleEditPurchaseOrderLine}
            handleGetItemCard={handleGetItemCard}
            locationData={locations}
          />
        </CustomModal>
      )}
      <CustomModal
        show={showItemCardModal}
        handleClose={handleItemCardCloseModal}
        title=""
        backdrop="static"
      >
        <ItemCard
          id={selectedProductId}
          handleShowInventoryModal={handleShowInventoryModal}
          selectedInventory={selectedInventory}
          itemCardSelectedTab={itemCardSelectedTab}
          setItemCardSelectedTab={setItemCardSelectedTab}
        />
      </CustomModal>
      <CustomModal
        show={showInventoryModal}
        handleClose={handleHideInventoryModal}
        title=""
        backdrop="static"
      >
        <InventoryModal
          selectedInventory={selectedInventory}
          handleHideInventoryModal={handleHideInventoryModal}
        />
      </CustomModal>
      <CustomModal
        show={showPurchaseOrderLineModal}
        handleClose={handleClosePurchaseOrderLineModal}
        title={isEdit ? "Update PurchaseOrder Line" : "Add Purchase Order Line"}
        backdrop="static"
      >
        <AddEditPurchaseOrderLineModal
          handleAllocationChange={handleAllocationChange}
          handleLineChange={handleLineChange}
          handleProductLineChange={handleProductLineChange}
          products={products}
          handleSavePurchaseOrderLine={handleSavePurchaseOrderLine}
          handleUpdatePurchaseOrderLine={handleUpdatePurchaseOrderLine}
          purchaseOrderLineChange={purchaseOrderLineChange}
          isEdit={isEdit}
          handleCancel={handleCancel}
          error={error}
          validateInput={validatePurchaseOrderLineInput}
        />
      </CustomModal>
      <CustomModal
        show={showAddModal}
        handleClose={handleCloseAddModal}
        title="Add PurchaseOrder"
        backdrop="static"
      >
        <AddEditPurchaseOrderModal
          handleAddPurchaseOrder={handleAddPurchaseOrder}
          handleUpdatePurchaseOrder={handleUpdatePurchaseOrder}
          handleChangePurchaseOrder={handleChangePurchaseOrder}
          brands={brands}
          season={season}
          validateInput={validateInput}
          validation={validation}
          handleDropDownChange={handleDropDownChange}
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
          handlePurchaseOrderCancel={handlePurchaseOrderCancel}
          selectedPurchaseOrder={selectedPurchaseOrder}
          locationData={locations}
        />
      </CustomModal>
      <CustomModal show={isImportLoading}>
        <Loading animationData={animationData} title="Import in progress..." />
      </CustomModal>

      {getPermission(user.permissions, PURCHASEORDER_VIEW) && (
        <Container fluid>
          <Container fluid>
            <Row>
              <Col md="12">
                <Card>
                  <Card.Body>
                    <Row>
                      <Col md="">
                        <DateRange
                          title="Date"
                          startDate={dateRange[0]}
                          endDate={dateRange[1]}
                          range={true}
                          handleChange={handleDatesChange}
                        />
                      </Col>
                      <Col md="">
                        <CustomSelect
                          title="Brand"
                          placeholder="Brand"
                          id={brandId}
                          data={brands}
                          handleChange={(e) => setBrandId(e.value)}
                          withAll={true}
                        />
                      </Col>
                      <Col md="">
                        <CustomSelect
                          title="Season"
                          placeholder="Season"
                          id={seasonId}
                          data={season}
                          handleChange={(e) => setseasonId(e.value)}
                          withAll={true}
                        />
                      </Col>
                      <Col md="">
                        <CustomSelect
                          title="Status"
                          placeholder="Status"
                          id={statusId}
                          data={status}
                          handleChange={(e) => setStatusId(e.value)}
                          withAll={true}
                        />
                      </Col>
                      <Col md="" className="archivedCol">
                        <Form.Check
                          onChange={Check}
                          type="switch"
                          id="custom-switch"
                        />
                        <h5 className="archived" style={{ width: "119px" }}>
                          Include archived
                        </h5>
                      </Col>
                      {getPermission(user.permissions, PURCHASEORDER_EDIT) && (
                        <>
                          <Col md="">
                            <Button
                              onClick={handleExportTemplate}
                              style={{ width: "130px" }}
                              className="btn-fill PO"
                              variant="primary"
                              download="Export.xlsm"
                            >
                              Export Template
                            </Button>
                          </Col>
                          {getPermission(
                            user.permissions,
                            PURCHASEORDER_EDIT
                          ) && (
                            <Col md="">
                              <Button
                                onClick={handleSubmit}
                                style={{ width: "163px" }}
                                className="btn-fill PO"
                                variant="primary"
                              >
                                Import Purchase Order
                              </Button>
                            </Col>
                          )}
                          <Form.Control
                            placeholder="Purchase Order Number"
                            type="file"
                            accept=".xlsm"
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            onChange={handleFileUpload}
                          ></Form.Control>
                          {getPermission(
                            user.permissions,
                            PURCHASEORDER_EDIT
                          ) && (
                            <Col md="">
                              <Button
                                className="btn-fill PO"
                                variant="primary"
                                style={{ marginleft: "0px" }}
                                onClick={() => handleAddModelPurchaseOrder()}
                              >
                                Add
                              </Button>
                            </Col>
                          )}
                        </>
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col md="12">
                <PurchaseOrderTable
                  isLoading={isLoading}
                  data={data}
                  updateData={updateData}
                  PurchaseOrderLines={PurchaseOrderLines}
                  search={purchaseOrderSearch}
                  handleSearchChange={handlePurchaseOrderSearchChange}
                  handleGetExportPDF={handleGetExportPDF}
                  handleGetExportExcel={handleGetExportExcel}
                  handleGetLabelPDF={handleGetLabelPDF}
                  handleDeletePurchaseOrder={handleDeletePurchaseOrder}
                  handleClosePurchaseOrder={handleClosePurchaseOrder}
                  handleReopenPurchaseOrder={handleReopenPurchaseOrder}
                />
              </Col>
            </Row>
          </Container>
        </Container>
      )}
      {!getPermission(user.permissions, PURCHASEORDER_VIEW) && (
        <Container className="mt-4 ms-4">
          You are not authorized to view this page
        </Container>
      )}
    </>
  );
}

export default PurchaseOrder;
