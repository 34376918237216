import React, { useEffect, useState } from "react";
import {
  OverlayTrigger,
  Table,
  Card,
  Col,
  Row,
  Button,
  Form,
  Tooltip,
} from "react-bootstrap";
import { showSweetAlert } from "components/Shared/CustomAlert";

const StocktakeLinesTable = (props) => {
  let stocktakeId = null;
  const [stocktakeLines, setStocktakeLines] = useState(props.data);

  const [updatedPage, setUpdatedPage] = useState(false);

  const [show0Variance, setShow0Variance] = useState(true);
  const handleShow0Variance = () => {
    setShow0Variance((prev) => !prev);
  };

  const [showUnscannedProducts, setShowUnscannedProducts] = useState(true);
  const handleShowUnscannedProducts = () => {
    setShowUnscannedProducts((prev) => !prev);
  };

  const [showSoldProducts, setShowSoldProducts] = useState(false);
  const handleShowSoldProducts = () => {
    setShowSoldProducts((prev) => !prev);
  };

  useEffect(() => {
    recalculateStocktakeLines();
  }, [show0Variance, showUnscannedProducts, showSoldProducts, props.data]);

  const recalculateStocktakeLines = () => {
    let newLines = props.data;

    if (!show0Variance) {
      newLines = newLines.filter(
        (item) => item.onHand - item.sold - item.totalStock !== 0
      );
    }

    if (!showUnscannedProducts) {
      newLines = newLines.filter((item) => item.scanned !== 0);
    }

    if (showSoldProducts) {
      newLines = newLines.filter((item) => item.sold !== 0);
    }

    setStocktakeLines(newLines);
  };

  if (props.data && props.data.length > 0)
    stocktakeId = props.data[0].stocktakeId;

  const checkPageUpdated = () => {
    if (updatedPage)
      showSweetAlert(
        "warningOkOnly",
        "Please save the changes before confirming the stocktake"
      );
    else props.handleConfirmStocktake(stocktakeId);
  };

  const handleSaveStocktake = () => {
    setUpdatedPage(false);
    props.handleSaveVariances();
  };

  const returnTableData = () => {
    return stocktakeLines.map((stocktake, index) => {
      if (stocktake === null || stocktake === undefined) return;

      const {
        id,
        stocktakeId,
        productId,
        productName,
        checkRequired,
        checkNotes,
        scanned,
        totalStock,
        variance,
        adjustment,
        onHand,
        committed,
        inTransit,
        sold,
        brandCode,
        colour,
        size,
        barcode,
        sku,
        adjustmentAction,
        adjustmentCost,
        difference = onHand - sold - totalStock,
      } = stocktake;

      return (
        <tr key={index}>
          <td>{sku}</td>
          <td>{productName}</td>
          <td>{size}</td>
          <td>{colour}</td>
          <td>{brandCode}</td>
          <td>{barcode}</td>
          <td>{onHand}</td>
          <td>{committed}</td>
          <td>
            <a
              href="#id"
              onClick={() =>
                props.handleInTransitClick(
                  "InTransit",
                  id,
                  props.stocktakeLocationId
                )
              }
            >
              {inTransit}
            </a>
          </td>
          <td>{sold}</td>
          <td>
            <div>{scanned <= 0 ? "Not scanned" : { scanned }}</div>
          </td>
          <td>
            <div className="d-flex justify-content-between align-items-center">
              <i
                className="fas fa-minus"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUpdatedPage(true);
                  props.handleAdjustTotal(id, totalStock - 1);
                }}
              />
              {totalStock} (
              {difference > 0
                ? `${difference} missing`
                : difference < 0
                ? `${Math.abs(difference)} extra`
                : "perfect"}
              )
              <i
                className="fas fa-plus pr-1"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setUpdatedPage(true);
                  props.handleAdjustTotal(id, totalStock + 1);
                }}
              />
            </div>
          </td>
          <td style={{ width: "20px" }}>
            <div className="d-flex">
              {checkRequired && (
                <OverlayTrigger
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                  overlay={
                    <Tooltip id="tooltip-255158527">{checkNotes}</Tooltip>
                  }
                >
                  <Button
                    className="btn-wd btn-outline mr-1"
                    type="button"
                    variant="warning"
                  >
                    <span className="btn-label">
                      <i className="fas fa-exclamation-triangle"></i>
                    </span>
                  </Button>
                </OverlayTrigger>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title
            as="h4"
            className="d-flex justify-content-between align-items-center"
          >
            Stocktake Lines
            <div className="d-flex justify-content-between">
              <Form.Check className="pe-3">
                <Form.Check.Label>
                  <Form.Check.Input
                    defaultValue=""
                    type="checkbox"
                    checked={show0Variance}
                    onChange={() => handleShow0Variance()}
                  ></Form.Check.Input>
                  <span className="form-check-sign"></span>
                  Show 0 Variance
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="pe-3">
                <Form.Check.Label>
                  <Form.Check.Input
                    defaultValue=""
                    type="checkbox"
                    checked={showUnscannedProducts}
                    onChange={() => handleShowUnscannedProducts()}
                  ></Form.Check.Input>
                  <span className="form-check-sign"></span>
                  Show Unscanned Products
                </Form.Check.Label>
              </Form.Check>
              <Form.Check className="pe-3">
                <Form.Check.Label>
                  <Form.Check.Input
                    defaultValue=""
                    type="checkbox"
                    checked={showSoldProducts}
                    onChange={() => handleShowSoldProducts()}
                  ></Form.Check.Input>
                  <span className="form-check-sign"></span>
                  Show Sold Products
                </Form.Check.Label>
              </Form.Check>

              <Button
                className="btn-fill"
                variant="primary"
                disabled={props.stocktakeStatus === "Complete"}
                onClick={() => checkPageUpdated()}
              >
                Confirm Stocktake
              </Button>
            </div>
          </Card.Title>
        </Card.Header>
        {/* todo - if there is data BUT its also 0 scanned then show diff message */}
        <Card.Body className="table-responsive p-0" style={{ height: 550 }}>
          {stocktakeLines && stocktakeLines.length == 0 && !props.isLoading && (
            <p
              style={{ height: 550 }}
              className="d-flex justify-content-center align-items-center text-center"
            >
              No data found.
            </p>
          )}
          {stocktakeLines && stocktakeLines.length > 0 && !props.isLoading && (
            <Col md="12">
              <Table className="table-hover table-striped w-full">
                <thead>
                  <tr>
                    <th>SKU</th>
                    <th>Name</th>
                    <th>Size</th>
                    <th>Colour</th>
                    <th>Brand Code</th>
                    <th>Barcode</th>
                    <th>On Hand</th>
                    <th>Comitted</th>
                    <th>In Transit</th>
                    <th>Sold</th>
                    <th>Scanned</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{returnTableData()}</tbody>
              </Table>
            </Col>
          )}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <Button
            className="btn-fill"
            variant="primary"
            disabled={props.stocktakeStatus === "Complete"}
            onClick={() => handleSaveStocktake()}
          >
            Save
          </Button>
        </Card.Footer>
      </Card>
    </>
  );
};

export default StocktakeLinesTable;
